/* eslint-disable import/prefer-default-export */

/**
 * Override and disable the Gatsby Link functionality where it scrolls the user to the last known
 * position/area on a page.
 *
 * This means that the page will always start at the top of the page, _unless_ the route has a
 * hash route such as `/page#example`. If it does has a hash route, it would still jump to the
 * anchor on the page.
 *
 * @param {object} params
 * @param {object} params.routerProps The reach router properties
 * @returns {boolean} True or false based on if the page should scroll or not
 */
export const shouldUpdateScroll = ({ routerProps }) => !!routerProps.location.hash;
